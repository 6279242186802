export const CREATIONS = [
  {
    src: '/images/creations/bonnets.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/images/creations/lingettes.jpg',
    width: 806,
    height: 605
  },
  {
    src: '/images/creations/charlottes.jpg',
    width: 1,
    height: 1
  },
]
